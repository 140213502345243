<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.outsource") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>

      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end"
      >
        <el-button
          size="mini"
          @click="drawerCreate = true"
          icon="el-icon-circle-plus-outline"
        >
          {{ $t("message.create") }}
        </el-button>

        <crm-column-settings
          :columns="columns"
          :modelName="'outsource'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>

    <table
      style="background: #fff"
      class="table table-bordered table-hover"
      v-loading="loadingData"
    >
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input
              size="mini"
              v-model="filterForm.patient_id"
              :placeholder="columns.patient_id.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.service_id.show">
            <el-input
              size="mini"
              v-model="filterForm.service_code_id"
              :placeholder="columns.service_id.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.created_at.show">
            <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
            >
            </el-date-picker>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="item in list" :key="item.id" class="cursor-pointer">
          <td v-if="columns.id.show">{{ item.order_service.order_id }}</td>
          <td v-if="columns.patient_id.show">
            {{
              item.order_service.order && item.order_service.order.patient
                ? item.order_service.order.patient.surname +
                  " " +
                  item.order_service.order.patient.first_name
                : ""
            }}
          </td>
          <td v-if="columns.service_id.show">
            <span v-if="item.order_service">
              {{ item.order_service.service.name }}
            </span>
            <span v-else> {{ "-" }} </span>
          </td>
          <td v-if="columns.created_at.show">
            {{ item.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <el-button
              round
              @click="show(item)"
              size="mini"
              type="primary"
              icon="el-icon-view"
              >{{ $t("message.show_0") }}</el-button
            >
            <el-button @click="edit(item)" type="primary" icon="el-icon-edit">{{
              $t("message.edit")
            }}</el-button>
            <el-button @click="deleteOutsource(item.id)" type="danger">{{
                $t("message.delete")
              }}</el-button>
          </td>
        </tr>
      </transition-group>
    </table>

    <el-drawer
      :title="$t('message.new_type_expence')"
      :visible.sync="drawerCreate"
      size="60%"
      :drawer="drawerCreate"
      @close="reloadIfChanged"
    >
      <div>
        <crm-create
          :reloadModel="reopenUpdate"
          @c-close="reloadIfChanged('drawerCreate')"
        ></crm-create>
      </div>
    </el-drawer>
    <el-drawer
      size="60%"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
        :outsource_id="selected_id"
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
      >
      </crm-update>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/createOutsource.vue";
import CrmUpdate from "./components/updateOutsource.vue";
import CrmShow from "./components/Parts/components/crm-show";
import list from "@/utils/mixins/list";
import Inventory from "../../components/inventory-select/select-service_name-inventory";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list, drawer],
  name: "outsource",
  components: {
    CrmShow,
    CrmCreate,
    CrmUpdate,
    "select-service-name-inventory": Inventory,
  },
  data() {
    return {
      drawerCreate: false,
      drawerShow: false,
      drawerCreateCoculation: false,
      reopenUpdate: false,
      drawerUpdate: false,
      reopenShow: false,
      selected_id: null,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      edit_open: false,
      backUrl: process.env.VUE_APP_URL_DOCS,
      drawer: {
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      auth_role: "auth/role",
      list: "outsource/inventory",
      columns: "outsource/columns",
      pagination: "outsource/pagination",
      filter: "outsource/filter",
      sort: "outsource/sort",
      states: "states/list",
      model: "orders/relation_model",
      token: "auth/token",
      user_id: "auth/id",
    }),
  },
  async created() {},
  async mounted() {},
  watch: {
    columns: {
      handler: function () {},
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "outsource/inventory",
      updateSort: "outsource/updateSort",
      updateFilter: "outsource/updateFilter",
      updateColumn: "outsource/updateColumn",
      updateModel: "orders/relationShow",
      updatePagination: "outsource/updatePagination",
      empty: "outsource/empty",
      delete: "outsource/destroy",
      refreshData: "outsource/refreshData",
      showModel: "orders/relationShow",
    }),
    fetchData() {
      let patient = this.model.patient ? this.model.patient : "";
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        patient: true,
        service: true,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    disable(item) {
      if (
        item.doctor_id == this.user_id ||
        item.doctor_ids.find((id) => {
          return id.doctor_id == this.user_id;
        }) ||
        this.auth_role.slug == "admin"
      ) {
        return true;
      }
    },
    openedDrawer(ref) {
      this.reopenUpdate = true;
      this.edit_open = true;
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    async edit(model) {
      const query = { id: model.order_service.order_id, relation: true };
      await this.showModel(query)
        .then(async (res) => {
          this.selected_id = model.id;
          this.drawer.update.status = true;
        })
        .catch((err) => {});
    },
    async show(model) {
      const order_service = model.order_service;
      var name =
        order_service.order_id +
        "_" +
        order_service.service_id +
        "_" +
        order_service.order.patient.surname +
        " " +
        order_service.order.patient.first_name;
      var path = model.file.file;
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        this.backUrl +
          "/file_orientation?order_id=" +
          model.order_id +
          "&service_id=" +
          model.service_id +
          "&name=" +
          name +
          "&user=" +
          this.token +
          "&status=show" +
          "&type_file=pdf" +
          "&filename=" +
          path +
          "&id=" +
          model.id +
          "&user_type=doctor"
      );
      link.setAttribute("target", "_blank");
      link.click();

      return;
    },
    afterCloseDrawer() {
      this.reloadIfChanged("createDrawer");
      this.drawerCreateCoculation = false;
      this.edit_open = false;
    },
    emptyModel() {
      this.empty();
    },
    deleteOutsource(id) {
      this.delete(id);
    },
  },
};
</script>
